@import '../../06-scss/abstracts/variables';

.alert {
    border-radius: 3px;
    padding: 1rem 0.7rem;
    background: lighten($error, 40%);
    border: 1px solid lighten($error, 30%);
    margin: 0.5rem 0;
    & p {
        color: $error;
    }

    &.success {
        background: lighten($primary, 40%);
        border: 1px solid lighten($primary, 30%);
        & p {
            color: $primary;
        }
    }
}
