.stepperCheck {
    display: flex;
    gap: 1rem;
}

.stepperCheckIcon {
    color: #6ab63e;
    font-size: 2rem;
    z-index: 1;
    background: white;
    border-radius: 50%;
}

.stepperCheckContainer {
    position: relative;
    width: max-content;
}

.stepperCheckContainer span.show {
    content: '';
    position: absolute;
    left: 50%;
    top: 2rem;
    background: gray;
    transform: translateX(-50%);
    width: 2px;
    height: 50px;
    z-index: -1;
}
