@import '../../06-scss/abstracts/variables';
@import '../../06-scss/abstracts/mixins';

.statusStep {
    font-family: 'Source Sans Pro', sans-serif;
    display: flex;
    flex-direction: column;
    position: relative;

    flex-grow: 1;
    flex-basis: 6rem;

    &__icon {
        background-color: #fff;
        border-radius: 50%;
        border: 0.125rem solid #000;
        color: #000;
        font-size: 2.5rem;
        margin-right: 1.25rem;
        margin-bottom: 1rem;
        padding: 0.25rem;
    }

    &_status_disabled &__icon {
        background-color: $warning;
    }

    &_status_ongoing &__icon {
        background-color: $secondary;
        color: #fff;
    }

    &_status_done &__icon {
        background-color: $primary;
        color: #fff;
    }

    &__info {
        color: $secondary;
        font-size: 0.75rem;
        line-height: 1.5;
    }

    &__title {
        font-size: inherit; // Use info font-size instead of browser default
        font-weight: 700;
    }
}

@include media-breakpoint(lg) {
    .statusStep {
        flex-direction: row;

        &__info {
            font-size: 1rem;
        }

        &__title {
            font-size: 1.125rem;
        }

        // Vertical line through icons
        &:not(:last-child)::after {
            background: #000;
            content: '';
            display: block;
            height: 100%;
            margin-left: 1.2rem;
            margin-top: 1.2rem;
            position: absolute;
            width: 0.125rem;
            z-index: -1;
        }
    }
}