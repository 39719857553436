@import '../../06-scss/abstracts/variables';

.modal {
    position: fixed;
    top: 0%;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    transition: visibility 0.3s ease;
    &.show {
        visibility: visible;
        z-index: 99;
        background-color: rgba(0, 0, 0, 0.2);
    }

    &__content {
        padding: 1rem;
        width: 90%;
        max-width: 700px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
        position: fixed;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        opacity: 0;
        border-radius: 10px;
        border-left: 4px solid $primary;
        border-right: 4px solid $primary;
        transition: opacity 0.3s ease, top 0.3s ease;
        &.show {
            top: 50%;
            opacity: 1;
        }
    }

    &__header {
        display: flex;
        background: $primary;
        justify-content: right;
        align-items: center;
        margin-bottom: 1rem;
        height: 50px;
    }

    &__close {
        cursor: pointer;
        color: #fff;
        font-size: 1.3rem;
        margin-right: 1rem;
        transition: color 0.3s ease;
        &:hover {
            color: $secondary;
        }
    }
    &__body {
        margin: 1rem 0;
        overflow: auto;
        max-height: 400px;

        & > * {
            margin-bottom: 1rem;
        }
    }
}
