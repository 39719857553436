@import "../../06-scss/abstracts/variables";
@import "../../06-scss/abstracts/mixins";

.footer {
  padding: 1.25rem;
  border-top: 0.0625rem solid #ddd;

  &__logo {
    display: block;
    height: auto;
    max-width: 12rem;
    width: 100%;
    margin-bottom: 1rem;
    margin-right: auto;
  }

  @include media-breakpoint(xl) {
    display: flex;
    align-items: center;

    &__logo {
      margin-bottom: 0;
    }
  }


  &__icon {
    color: $primary;
    margin-left: 0.5rem;
  }
}
