@import '../../06-scss/abstracts/variables';
@import '../../06-scss/abstracts/mixins';

.step {
    --num-size: 1.5rem;

    position: relative;
    flex: 1 0 5rem;

    &_status_active {
        --label-color: #{$primary};
        --line-color: #{$primary};
        --num-background-color: #{$primary};
        --num-color: #fff;
    }

    &_status_done {
        --label-color: #{$secondary};
        --line-color: #{$secondary};
        --num-background-color: #{$secondary};
        --num-color: #fff;
    }

    @include media-breakpoint(sm) {
        --num-size: 1.75rem;
    }

    @include media-breakpoint(sm2) {
        --label-max-width: 6rem;
        --label-size: 1rem;
        --num-size: 2rem;
    }
    
    @include media-breakpoint(md) {
        --num-inset-thickness: 0.125rem;
        --num-size: 2.25rem;
    }

    &::before {
        --line-thickness: 0.125rem;

        background: var(--line-color, #ccc);
        content: '';
        height: var(--line-thickness);
        margin-top: calc((var(--num-size) - var(--line-thickness)) / 2);
        position: absolute;
        width: 100%;
        z-index: -1;
    }

    &:first-child::before,
    &:last-child::before {
        width: 50%;
    }

    &:first-child::before {
        right: 0;
    }

    &__content {
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    &__num {
        align-items: center;
        background-color: var(--num-background-color, $light-gray);
        border-radius: 50%;
        border: 0.0625rem solid var(--line-color, #ccc);
        box-shadow: 0 0 0 var(--num-inset-thickness, 0.0625rem) #fff inset;
        color: var(--num-color, $outline);
        display: flex;
        height: var(--num-size);
        justify-content: center;
        margin-bottom: 0.25rem;
        padding: 0.25rem;
        text-align: center;
        width: var(--num-size);
    }

    &__label {
        color: var(--label-color, $outline);
        font-size: var(--label-size, 0.75rem);
        max-width: var(--label-max-width, 4rem);
        text-align: center;
    }
}