@import '../../06-scss/abstracts/variables';
.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;

    & svg {
        color: $primary;
        font-size: 1.2rem;
    }

    &:hover > span,
    &:hover > ul {
        display: block;
    }

    & span,
    & ul {
        display: none;
        position: absolute;
        z-index: 99;
        background: white;
        top: -50%;
        right: -200px;
        width: 200px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        padding: 0.5rem 1rem;
        font-size: 0.7rem;
    }
}
