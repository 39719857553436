@import '../../06-scss/abstracts/variables';

.normal {
    cursor: pointer;
    text-decoration: none;
}

.btn-link {
    background: $primary;
    padding: 0.5rem 0.8rem;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    display: inline-block;
    transition: background 0.3s ease;
    cursor: pointer;

    &:hover {
        background: $secondary;
    }

    &.secondary {
        border-radius: 25px;
        padding: 0.5rem 1rem;
        background: white;
        color: $secondary;
        border: 1px solid $primary;
        transition: background 0.3s ease, color 0.3s ease;
        &:hover {
            background: $secondary;
            color: #fff;
        }
    }

    &.default {
        border-radius: 5px;
        @extend .btn-link.secondary;
    }

    &.hyperlink {
        color: blue;
        text-decoration: underline;
    }

    &.disabled {
        pointer-events: none;
        border-color: $outline;
        color: $outline;
    }
}
