@import '../../06-scss/abstracts/variables';

.checkbox {
    border: 1px solid gray;
    padding: 6px;
    width: 1px;
    height: 1px;
    cursor: pointer;
    position: relative;
    display: inline-block;
    border-radius: 3px;

    &.error {
        background: lighten($error, 10%);
        color: white;
    }
    & input {
        display: none;
    }

    &__check {
        &::after {
            content: '\2714';
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            transform: translate(-50%, -50%);
            transition: transform 0.3s ease;
            transform: scale(0);
            font-size: 10px;
        }
    }

    & input:checked + &__check::after {
        transform: scale(1);
    }
}
