@import '../../06-scss/abstracts/variables';

.unlabeled-dropdown {
    --border: 0.125rem solid var(--border-color, #ddd);

    border-radius: 0.25rem;
    border: var(--border);
    display: flex;
    overflow: hidden;
    position: relative;
    transition: border-color 0.3s ease;

    &:focus-within,
    &_has-value {
        --background-color: #fff;
        --border-color: #{$primary};
    }

    &:focus-within {
        outline: 0.125rem solid var(--outline-color, #{lighten($primary, 20%)});
    }

    &_error,
    &_error:focus-within {
        --border-color: #{$error};
        --outline-color: #{lighten($error, 20%)};
    }

    &_disabled {
        --background-color: #{$light-gray};
        --border-color: #{$light-gray};
    }


    &_compact {
        --dropdown-padding: 0.25rem;
        --icon-width: 1.75rem;
    }

    &__select {
        background-color: var(--background-color, #f2f2f2);
        border: 0;
        outline: none;
        padding: var(--dropdown-padding, 0.75rem);
        transition: background-color 0.3s ease;
        width: 100%;
    }

    &__icon {
        background-color: #f2f2f2;
        border-left: var(--border);
        height: 100%;
        pointer-events: none;
        position: absolute;
        right: 0;
        transition: border-color 0.3s ease;
        width: var(--icon-width, 2.5rem);

        &::after {
            border: solid 0.35rem transparent;
            border-top: none;
            border-bottom-color: #000;
            color: black;
            content: '';
            height: 0;
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 0;
        }
    }
}
