//colors
$primary: #6ab63e;
$secondary: #074442;
$outline: #7f7f7f;
$light-gray: #fafafa;
$light-green: #f2f8ed;

$error: rgb(173, 71, 71);
$highlight: #568bb4;
$warning: #ffef4a;

// dark green -
// apple green -
// Light gray - #fafafa
// gray - outline - #7f7f7f

//layout
$max-width: 1200px;
$layout-padding: 1.25rem;

$grid-breakpoints: (
    xs: 481px,
    sm: 576px,
    sm2: 681px,
    md: 768px,
    md2: 834px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
);
