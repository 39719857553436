@import '../../06-scss/abstracts/variables';
.button {
    background: var(--color, $primary);
    padding: 0.5rem 0.8rem;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    border: none;
    display: inline-block;
    transition: background 0.3s ease;
    cursor: pointer;

    &:hover {
        background: $secondary;
    }

    &_outline {
        background: white;
        color: var(--color, $primary);
        border: 1px solid var(--color, $primary);
        transition: background 0.3s ease, color 0.3s ease;

        &:hover {
            color: #fff;
        }
    }

    &_disabled {
        pointer-events: none;
        background: $outline;
    }

    &_color_blue {
        --color: #{$highlight};
    }

    &_link-button {
        background-color: transparent;
        color: $primary;
        font-size: inherit;
        font-family: inherit;
        padding: 0;
        text-decoration: underline;

        &:hover {
            background: transparent;
        }
    }

    &_block {
        display: block;
        width: 100%;
    }
}
