@import '../../06-scss/abstracts/variables';

.loader {
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;

    &__dot {
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        background: var(--color, $primary);
        animation: loading 1.5s infinite var(--delay, 100ms) alternate ease;
        animation-fill-mode: both;
        transform: scale(0);

        &_2 {
            --color: #{$secondary};
            --delay: 250ms;
        }

        &_3 {
            --delay: 350ms;
        }
    }
}

@keyframes loading {
    from {
        transform: scale(0);
    }
    50% {
        transform: scale(1);
    }
    to {
        transform: scale(0);
    }
}
