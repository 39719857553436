@import '../../06-scss/abstracts/variables';
.custom-datepicker {
    --background-color: #f2f2f2;
    --border-color: #ddd;
    --outline-color: #{lighten($primary, 20%)};

    position: relative;
    width: 100%;
    display: inline-block;

    &.has-value {
        --background-color: #fff;
        --border-color: #{$primary};
    }

    &.error {
        --border-color: #{$error};
        --outline-color: #{lighten($error, 20%)};
    }

    &__container {
        position: relative;
        border-radius: 0.25rem;

        &:focus-within {
            --background-color: #fff;

            outline-color: var(--outline-color);
            outline-style: solid;
            outline-width: 0.125rem;
        }
    }

    &__logo {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        font-size: 1rem;
        border-left: 2px solid var(--border-color);
        width: 40px;
        padding: 10px;
        pointer-events: none;
    }

    &__input {
        border: none;
        appearance: none;
        background-color: var(--background-color);
        padding: 12px;
        border-radius: 3px;
        width: 100%;
        outline: none;
        border: 2px solid var(--border-color);
        transition-property: background-color, border;
        transition-duration: 300ms;
    }
}

.react-datepicker-wrapper {
    display: block;
}
