@import '../../06-scss/abstracts/variables';

.product-table {
    $border: 0.0625rem solid $primary;
    $border-radius: 1rem;

    margin: 0 (-$layout-padding);
    overflow-x: auto;

    &__table {
        border-spacing: 0;
        padding: 0 $layout-padding;
        width: 100%;
    }

    &__empty-header-cell {
        min-width: 12rem;
        width: 35%;
    }

    &__header-cell {
        background-color: $primary;
        border-radius: $border-radius $border-radius 0 0;
        color: #fff;
        padding: 0.5rem 1rem;
    }

    &__header-small-text {
        display: block;
        font-weight: normal;
    }

    &__body-row {
        background-color: #f1f1f1;

        &:nth-child(even) {
            background-color: #fff;
        }
    }

    &__body-row-header {
        font-weight: normal;
        text-align: left;
        padding: 1rem;
    }

    &__body-cell {
        border-left: $border;
        text-align: center;
        padding: 1rem;

        &:last-child {
            border-right: $border;
        }
    }

    &__icon {
        color: $primary;
        font-size: 1.25rem;
    }

    &__footer-cell {
        border-radius: 0 0 $border-radius $border-radius;
        border-bottom: $border;
        border-left: $border;
        padding: 1rem;
        text-align: center;

        &:last-child {
            border-right: $border;
        }
    }

    &__action {
        background-color: $primary;
        border-radius: 10%;
        border: 0;
        color: #fff;
        cursor: pointer;
        display: inline-block;
        font-size: inherit;
        line-height: 1;
        margin: auto;
        padding: 1rem;
        text-decoration: none;
        transition: background-color .3s ease;
        white-space: nowrap;

        &:hover {
            background-color: $secondary;
        }
    }
}