@import '../../06-scss/abstracts/variables';
@import '../../06-scss/abstracts/mixins';

.modal-base {
    top: 33%;
    position: relative;
    margin: auto;
    padding: 0;
    opacity: 0;
    transition: 0.2s ease-in-out;
    transition-property: opacity, transform;
    transform: scale(0.8);
    max-width: 40rem;

    @include media-breakpoint(sm) {
        width: 90%;
    }
    @include media-breakpoint(md) {
        width: 75%;
    }
    @include media-breakpoint(lg) {
        width: 50%;
    }
}

.modal-content {
    background-color: #fefefe;
}

.modal-header {
    display: flex;
    padding: 1rem;
    background-color: $primary;
    color: white;

    .close {
        color: white;
        font-size: 2rem;
        font-weight: bold;
        cursor: pointer;
        margin-left: auto;
        &:hover {
            color: $secondary;
        }
    }
}

.modal-body {
    padding: 1.25rem 2rem;
    font-size: 1.25rem;
}

.modal-footer {
    padding: 1.25rem 1.5rem;
    color: white;
    display: flex;
    justify-content: space-around;

    button {
        font-size: 1.25rem;
        margin: 0 0.5rem;
        width: 100%;
    }
}

.modal-base_after-open {
    opacity: 1;
    transform: scale(1);
}

.modal-base_before-close {
    transform: scale(0.2);
    opacity: 0;
}

.overlay-base {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(66, 66, 66, 0.5);
    opacity: 0;
    transition: all 0.3s ease-out;
}

.overlay-base_after-open {
    opacity: 1;
}

.overlay-base_before-close {
    opacity: 0;
}
