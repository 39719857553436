@import '../../06-scss/abstracts/variables';

.radioButton {
    display: inline-block;

    &_disabled {
        color: $outline;
    }

    & span {
        padding: 0 1rem;
    }
}
