@import '../../06-scss/abstracts/variables';

.table-responsive {
    overflow-x: auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.custom-table {
    border-collapse: collapse;
    font-size: 1rem;
    border-radius: 5px 5px 0 0;
    width: 100%;

    & thead.hide {
        display: none;
    }

    & th {
        color: $highlight;
        text-align: left;
    }

    & th,
    & td {
        padding: 0.5rem 1rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .actions {
        display: flex;
        gap: 1rem;

        & svg {
            cursor: pointer;
        }
    }

    & > thead {
        & th {
            background: #f2f1f1;
        }
    }

    & > tbody {
        & td {
            background: #fff;
        }
    }
}
