@import '../../../06-scss/abstracts/variables';

h1.heading {
    color: $primary;
}

.policy__content {
    margin: 1rem 0;

    & > .clip-loader {
        text-align: center;
        align-items: center;
        justify-content: center;
        padding-top: 40px;
    }

    & > * {
        margin-bottom: 1rem;
    }
}
