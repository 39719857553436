* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body,
#root {
    min-height: 100vh;
}

#root {
    display: flex;
    flex-direction: column;
}

// Override native appearance for number fields. Specifically, remove their
// spinner controls.
// Currently commented out because there are no number fields in the app.
// Uncomment these when needed.

// // Chrome, Safari, Edge, Opera
// input::-webkit-outer-spin-button,
// input::-webkit-inner-spin-button {
//     -webkit-appearance: none;
//     margin: 0;
// }
// // Firefox
// input[type='number'] {
//     -moz-appearance: textfield;
// }

// There is no information as to why this is here. We'll keep it in here just in
// case.
img:-moz-loading {
    visibility: hidden;
}