@import '../../06-scss/abstracts/variables';
@import '../../06-scss/abstracts/mixins';

.navbar {
    align-items: center;
    background: #fff;
    box-shadow: 0 -5px 6px rgba(0, 0, 0, 1);
    display: flex;
    column-gap: 1rem;
    flex-direction: column;
    padding: 1rem;

    &__logo-img {
        aspect-ratio: 800 / 121;
        display: block;
        height: auto;
        max-width: 17.5rem;
        width: 100%;
    }

    &__right {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        margin-top: 1rem;
    }

    &__menu {
        background: $primary;
        border-radius: 0.25rem;
        display: flex;
        gap: 1rem;
    }

    // Styles that cover both <a> and <button> elements in the navbar menu.
    // Hence the redundant styles.
    &__menu-item {
        background-color: transparent;
        border: none;
        color: #fff;
        cursor: pointer;
        display: inline-block;
        font-size: 1rem;
        font-weight: bold;
        padding: 1rem;
        text-align: center;
        text-decoration: none;
    }

    &__menu-icon {
        margin-right: 0.5rem;
    }
}

@include media-breakpoint(md) {
    .navbar {
        flex-direction: row;
        justify-content: space-between;
        padding: 2rem;

        &__right {
            flex-direction: row;
            flex-wrap: nowrap;
            margin-top: 0;

            > * {
                white-space: nowrap;
            }
        }
    }
}
