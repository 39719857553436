@import '../../06-scss/abstracts/variables';

.custom-field {
    position: relative;
    width: 100%;
    display: inline-block;
    &__input {
        border: none;
        appearance: none;
        background: #f2f2f2;
        padding: 12px;
        border-radius: 3px;
        width: 100%;
        outline: none;
        border: 2px solid #ddd;
        transition-property: background-color, border;
        transition-duration: 300ms;

        &:focus {
            background-color: #fff;
            border-color: $primary;
            outline-color: lighten($primary, 20%);
            outline-style: solid;
            outline-width: 0.125rem;
        }

        &.has-value {
            background: $light-gray;
            border-color: $primary;
        }

        &.error {
            border-color: $error;

            &:focus {
                outline-color: lighten($error, 20%);
            }
        }

        &:disabled {
            border-color: $light-gray;
        }

    }

    &__label {
        display: flex;
    }
}
