@import '../../06-scss/abstracts/variables';

.textarea {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    &__input {
        --background-color: #f2f2f2;
        --border-color: #ddd;
        --outline-color: #{lighten($primary, 20%)};

        &:focus {
            --background-color: #{$light-gray};
            --border-color: #{$primary};

            outline-color: var(--outline-color);
            outline-style: solid;
            outline-width: 0.125rem;
        }

        &.has-value {
            --background-color: #{$light-gray};
            --border-color: #{$primary};
        }

        &.error {
            --border-color: #{$error};
            --outline-color: #{lighten($error, 20%)};
        }

        border: none;
        appearance: none;
        background-color: var(--background-color);
        padding: 12px;
        border-radius: 3px;
        width: 100%;
        outline: none;
        border: 2px solid var(--border-color);
        transition-property: background-color, border;
        transition-duration: 0.3s;
    }
}
