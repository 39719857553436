@import '../../06-scss/abstracts/variables';

.browseFile {
    --background-color: #{$secondary};
    --cursor: pointer;

    &_disabled {
        --background-color: #{$outline};
        --cursor: auto;
    }

    &__label {
        background-color: var(--background-color);
        padding: 0.6rem;
        color: white;
        border-radius: 5px;
        cursor: var(--cursor);
        user-select: none;
        transition: background 0.3s ease;
        width: 100%;
        display: block;
        text-align: center;
    }

    &__name {
        margin-top: 0.5rem;
        display: block;
    }

    &__input {
        display: none;
    }
}
