@import '../../../06-scss/abstracts/mixins';
@import '../../../06-scss/abstracts/variables';

h1.heading {
    color: $primary;
}

.faqs {
    padding-top: 20px;
    & > ul {
        padding-left: 20px;
    }

    &-questions {
        color: $secondary;
        font-weight: 900;
    }

    &-answers {
        padding: 15px 0 15px 0;
        margin-left: 1px;
    }
    & > .clip-loader {
        text-align: center;
        align-items: center;
        justify-content: center;
        padding-top: 40px;
    }

    //2nd UL
    &-otherList {
        padding-left: 20px;
        padding-bottom: 20px;
        & > li {
            padding: 5px 0 5px 0;
        }
    }
}

@include media-breakpoint(md) {
    .faqs {
        padding-top: 20px;
        & > ul {
            padding-left: 50px;
        }
    }
}
