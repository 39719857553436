@import '../../06-scss/abstracts/variables';
@import '../../06-scss/abstracts/mixins';

.statusProgress {
    display: flex;
    flex-wrap: wrap;
    padding: 0.5rem;
    margin: 3rem 0 1rem 0;

    @include media-breakpoint(lg) {
        flex-direction: column;
    }
}