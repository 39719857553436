@import '../../06-scss/abstracts/variables';
@import '../../06-scss/abstracts/mixins';

.step-progress-bar {
    overflow-x: auto;
    margin: 0 (-$layout-padding) 1.5rem;

    @include media-breakpoint(md) {
        margin: 1.5rem 0;
    }
}

.step-progress-bar__list {
    display: flex;
    list-style-type: none;
}